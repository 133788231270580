import VideoText from '@lyra/core/components/VideoText'
import { Stack } from 'tamagui'

const SkewTextBox = ({ title, width }: { title: string; width?: number }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width={width}
      height={32}
      backgroundColor="$invertedBg"
      skewX="-20deg"
    >
      <VideoText color="inverted" skewX="20deg">
        {title}
      </VideoText>
    </Stack>
  )
}

export default SkewTextBox
