import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import useSWR from 'swr'
import { Address } from 'viem'

import erc20Abi from '../abis/erc20Abi'
import lyraTokenSinkAbi from '../abis/lyraTokenSinkAbi'
import multiDistributorAbi from '../abis/multiDistributorAbi'
import { DepositNetwork } from '../constants/chains'
import {
  ARBITRUM_LYRA_TOKEN_ADDRESS,
  ARBITRUM_STK_LYRA_TOKEN_ADDRESS,
  ETHEREUM_STK_LYRA_TOKEN_ADDRESS,
  externalContractAddresses,
  MAINNET_LYRA_TOKEN_ADDRESS,
  OPTIMISM_LYRA_TOKEN_ADDRESS,
  OPTIMISM_OLD_STK_LYRA_TOKEN_ADDRESS,
  OPTIMISM_STK_LYRA_TOKEN_ADDRESS,
} from '../constants/contracts'
import { isMainnet } from '../constants/env'
import {
  ARBITRUM_SNAPSHOT_BLOCKNUMBER,
  ETHEREUM_MAINNET_SNAPSHOT_BLOCKNUMBER,
  OPTIMISM_SNAPSHOT_BLOCKNUMBER,
} from '../constants/lyraSnapshot'
import { DISTRIBUTOR_REWARD_BATCH_IDS, RewardEvent } from '../constants/rewards'
import {
  getPendingTradingRewards,
  getPostDistributionEpochLyraRewards,
  getPreDistributionEpochLyraRewards,
} from '../utils/rewards'
import { getNetworkClient } from '../utils/rpc'
import useAuth from './useAuth'

type FetchOptions = {
  hasCommittedTradingRewards?: boolean
  hasCommittedTradingRewardsAfterClaim?: boolean
}

type LyraBalances = {
  lyra: {
    [DepositNetwork.Arbitrum]: bigint
    [DepositNetwork.Ethereum]: bigint
    [DepositNetwork.Optimism]: bigint
    total: bigint
  }
  sunkLyra: {
    [DepositNetwork.Arbitrum]: bigint
    [DepositNetwork.Ethereum]: bigint
    [DepositNetwork.Optimism]: bigint
    total: bigint
  }
  stkLyra: {
    [DepositNetwork.Arbitrum]: bigint
    [DepositNetwork.Ethereum]: bigint
    [DepositNetwork.Optimism]: bigint
    total: bigint
  }
  pendingRewards: bigint
  committedRewards: bigint
  holderPoints: number
  holderPointsShare: number
}

type HolderPointsRouteResponse = {
  balance: number
  share: number
}

const EMPTY_LYRA_BALANCES: LyraBalances = {
  lyra: {
    [DepositNetwork.Arbitrum]: BigInt(0),
    [DepositNetwork.Ethereum]: BigInt(0),
    [DepositNetwork.Optimism]: BigInt(0),
    total: BigInt(0),
  },
  sunkLyra: {
    [DepositNetwork.Arbitrum]: BigInt(0),
    [DepositNetwork.Ethereum]: BigInt(0),
    [DepositNetwork.Optimism]: BigInt(0),
    total: BigInt(0),
  },
  stkLyra: {
    [DepositNetwork.Arbitrum]: BigInt(0),
    [DepositNetwork.Ethereum]: BigInt(0),
    [DepositNetwork.Optimism]: BigInt(0),
    total: BigInt(0),
  },
  pendingRewards: BigInt(0),
  committedRewards: BigInt(0),
  holderPoints: 0,
  holderPointsShare: 0,
}

const fetchLyraTokenBalances = async (
  ownerAddress: Address,
  options: FetchOptions = {}
): Promise<LyraBalances> => {
  if (!isMainnet) {
    return EMPTY_LYRA_BALANCES
  }

  const { hasCommittedTradingRewards, hasCommittedTradingRewardsAfterClaim } = options
  const [mainnetClient, optimismClient, arbitrumClient] = await Promise.all([
    getNetworkClient(DepositNetwork.Ethereum),
    getNetworkClient(DepositNetwork.Optimism),
    getNetworkClient(DepositNetwork.Arbitrum),
  ])
  const [
    [_arbLyraBalance, _arbStkLyraBalance, _arbSunkLyraBalance],
    [_opLyraBalance, _opStkLyraBalance, _opOldStkLyraBalance, _opSunkLyraBalance, _claimableAmount],
    [_ethLyraBalance, ethStkLyraBalance, _ethSunkLyraBalance],
    holderPointsRes,
    rewardEventsRes,
  ] = await Promise.all([
    arbitrumClient.multicall({
      contracts: [
        {
          abi: erc20Abi,
          address: ARBITRUM_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: ARBITRUM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: erc20Abi,
          address: ARBITRUM_STK_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: ARBITRUM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: lyraTokenSinkAbi,
          address: externalContractAddresses.lyraStakingSink.arbitrum,
          functionName: 'lockedBalances',
          args: [ownerAddress],
          ...{
            blockTag: ARBITRUM_SNAPSHOT_BLOCKNUMBER,
          },
        },
      ],
    }),
    optimismClient.multicall({
      contracts: [
        {
          abi: erc20Abi,
          address: OPTIMISM_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: OPTIMISM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: erc20Abi,
          address: OPTIMISM_STK_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: OPTIMISM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: erc20Abi,
          address: OPTIMISM_OLD_STK_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: OPTIMISM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: lyraTokenSinkAbi,
          address: externalContractAddresses.lyraStakingSink.optimism,
          functionName: 'lockedBalances',
          args: [ownerAddress],
          ...{
            blockTag: OPTIMISM_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: multiDistributorAbi,
          address: externalContractAddresses.multiDistributor,
          functionName: 'getClaimableAmountForUser',
          args: [DISTRIBUTOR_REWARD_BATCH_IDS, ownerAddress, OPTIMISM_LYRA_TOKEN_ADDRESS],
          ...{
            blockTag: OPTIMISM_SNAPSHOT_BLOCKNUMBER,
          },
        },
      ],
    }),
    mainnetClient.multicall({
      contracts: [
        {
          abi: erc20Abi,
          address: MAINNET_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: ETHEREUM_MAINNET_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: erc20Abi,
          address: ETHEREUM_STK_LYRA_TOKEN_ADDRESS,
          functionName: 'balanceOf',
          args: [ownerAddress],
          ...{
            blockTag: ETHEREUM_MAINNET_SNAPSHOT_BLOCKNUMBER,
          },
        },
        {
          abi: lyraTokenSinkAbi,
          address: externalContractAddresses.lyraStakingSink.ethereum,
          functionName: 'lockedBalances',
          args: [ownerAddress],
          ...{
            blockTag: ETHEREUM_MAINNET_SNAPSHOT_BLOCKNUMBER,
          },
        },
      ],
    }),
    fetch('/api/holder-points'),
    fetch('/api/rewards'),
  ])

  const stkLyraBalance =
    ethStkLyraBalance.status === 'success' ? ethStkLyraBalance.result : BigInt(0)
  const ethLyraBalance = _ethLyraBalance.status === 'success' ? _ethLyraBalance.result : BigInt(0)
  const ethSunkLyraBalance =
    _ethSunkLyraBalance.status === 'success' ? _ethSunkLyraBalance.result : BigInt(0)

  const arbLyraBalance = _arbLyraBalance.status === 'success' ? _arbLyraBalance.result : BigInt(0)
  const arbStkLyraBalance =
    _arbStkLyraBalance.status === 'success' ? _arbStkLyraBalance.result : BigInt(0)
  const arbSunkLyraBalance =
    _arbSunkLyraBalance.status === 'success' ? _arbSunkLyraBalance.result : BigInt(0)

  const opLyraBalance = _opLyraBalance.status === 'success' ? _opLyraBalance.result : BigInt(0)
  const opStkLyraBalance =
    _opStkLyraBalance.status === 'success' ? _opStkLyraBalance.result : BigInt(0)
  const opOldStkLyraBalance =
    _opOldStkLyraBalance.status === 'success' ? _opOldStkLyraBalance.result : BigInt(0)
  const opSunkLyraBalance =
    _opSunkLyraBalance.status === 'success' ? _opSunkLyraBalance.result : BigInt(0)

  const holderPoints = (await holderPointsRes.json()) as HolderPointsRouteResponse
  const rewardEvents = (await rewardEventsRes.json()) as RewardEvent[]
  const claimableAmount =
    _claimableAmount.status === 'success' ? _claimableAmount.result : BigInt(0)
  const postDistributionEpochLyraRewards = getPostDistributionEpochLyraRewards(rewardEvents)
  const committedRewards = hasCommittedTradingRewardsAfterClaim
    ? bigNumberToNumberUNSAFE(claimableAmount, WEI_DECIMALS) + postDistributionEpochLyraRewards
    : hasCommittedTradingRewards
    ? getPreDistributionEpochLyraRewards(rewardEvents) + postDistributionEpochLyraRewards
    : 0
  const pendingRewards = getPendingTradingRewards(rewardEvents)

  return {
    lyra: {
      [DepositNetwork.Arbitrum]: arbLyraBalance,
      [DepositNetwork.Ethereum]: ethLyraBalance,
      [DepositNetwork.Optimism]: opLyraBalance,
      total: arbLyraBalance + ethLyraBalance + opLyraBalance,
    },
    sunkLyra: {
      [DepositNetwork.Arbitrum]: arbSunkLyraBalance,
      [DepositNetwork.Ethereum]: ethSunkLyraBalance,
      [DepositNetwork.Optimism]: opSunkLyraBalance,
      total: arbSunkLyraBalance + opSunkLyraBalance + ethSunkLyraBalance,
    },
    stkLyra: {
      [DepositNetwork.Ethereum]: stkLyraBalance,
      [DepositNetwork.Arbitrum]: opStkLyraBalance + opOldStkLyraBalance,
      [DepositNetwork.Optimism]: arbStkLyraBalance,
      total: stkLyraBalance + opStkLyraBalance + opOldStkLyraBalance + arbStkLyraBalance,
    },
    pendingRewards: toBigNumber(pendingRewards, WEI_DECIMALS),
    committedRewards: toBigNumber(committedRewards, WEI_DECIMALS),
    holderPoints: holderPoints.balance,
    holderPointsShare: holderPoints.share,
  }
}

export default function useLyraBalances() {
  const { user } = useAuth()
  const { data, isLoading, mutate } = useSWR(
    [
      'LyraBalances',
      user?.ownerAddress,
      user?.flags?.hasCommittedTradingRewards,
      user?.flags?.hasCommittedTradingRewardsAfterClaim,
    ],
    ([_, ownerAddress, hasCommittedTradingRewards, hasCommittedTradingRewardsAfterClaim]) =>
      ownerAddress
        ? fetchLyraTokenBalances(ownerAddress, {
            hasCommittedTradingRewards,
            hasCommittedTradingRewardsAfterClaim,
          })
        : null,
    {
      keepPreviousData: true,
    }
  )
  return {
    data: data ?? EMPTY_LYRA_BALANCES,
    isLoading,
    mutate,
  }
}
