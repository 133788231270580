'use client'

import BodyText from '@lyra/core/components/BodyText'
import Link from '@lyra/core/components/Link'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import Tooltip from '@lyra/core/components/Tooltip'
import VideoText from '@lyra/core/components/VideoText'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatPercentage from '@lyra/core/utils/formatPercentage'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import { XStack, YStack } from 'tamagui'

import NextLinkButton from '../../components/common/NextLinkButton'
import SkewTextBox from '../../components/common/SkewTextBox'
import { PageId } from '../../constants/pages'
import { HELP_LYRA_MIGRAION_URL } from '../../constants/urls'
import useLyraBalances from '../../hooks/useLyraBalances'
import { getPagePath } from '../../utils/pages'

const TEXT_BOX_WIDTH = 85

export default function MigrationPageHelper() {
  const { data: lyraBalances, isLoading } = useLyraBalances()
  const totalLyraBalance = lyraBalances.lyra.total + lyraBalances.stkLyra.total
  const stakedLyraBalance =
    lyraBalances.stkLyra.total + lyraBalances.sunkLyra.total + lyraBalances.committedRewards

  return (
    <>
      <Section noTopBorder isLarge>
        <Section.Header
          title="▙ LYRA to DRV Migration"
          subtitle={
            <>
              The LYRA snapshot was taken on May 8 12:00am UTC. All LYRA balances will be migrated
              1:1 to DRV with the same total supply in Q3 2024. Note: Holder Points are not fungible
              with Trading Points.&nbsp;
              <Link label="Learn more" href={HELP_LYRA_MIGRAION_URL} />
            </>
          }
          rightContent={
            <NextLinkButton label="History" href={getPagePath({ page: PageId.RewardsHistory })} />
          }
        />
        <Section.XStack>
          <Notice
            width="fit-content"
            status="warning"
            message="Purchasing LYRA after the snapshot confers no rights to DRV and may result in loss of funds"
          />
        </Section.XStack>
        <Section.Separator />
        <Section.XStack alignItems="center">
          <SkewTextBox title="LYRA" width={TEXT_BOX_WIDTH} />
          <BodyText size="lg" color="secondary">
            LYRA will be migrated 1:1 to DRV with the same total supply
          </BodyText>
          <XStack marginLeft="auto">
            <Tooltip
              trigger={
                <XStack cursor="help" alignItems="center">
                  <VideoText marginLeft="auto" color="green" size="lg" textAlign="right">
                    {isLoading
                      ? '...'
                      : formatNumber(bigNumberToString(totalLyraBalance, WEI_DECIMALS))}{' '}
                    LYRA
                  </VideoText>
                  <BodyText color="secondary" size="lg">
                    &nbsp;[?]
                  </BodyText>
                </XStack>
              }
              content={
                lyraBalances.lyra.total !== BigInt(0) ? (
                  <YStack gap="$2">
                    <YStack>
                      <BodyText>Ethereum</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(lyraBalances.lyra.ethereum, WEI_DECIMALS)
                        )}{' '}
                        LYRA
                      </BodyText>
                    </YStack>
                    <YStack>
                      <BodyText>Arbitrum</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(lyraBalances.lyra.arbitrum, WEI_DECIMALS)
                        )}{' '}
                        LYRA
                      </BodyText>
                    </YStack>
                    <YStack>
                      <BodyText>Optimism</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(lyraBalances.lyra.optimism, WEI_DECIMALS)
                        )}{' '}
                        LYRA
                      </BodyText>
                    </YStack>
                  </YStack>
                ) : null
              }
            />
          </XStack>
        </Section.XStack>
        <Section.Separator />
        <Section.XStack alignItems="center">
          <SkewTextBox title="Staked" width={TEXT_BOX_WIDTH} />
          <BodyText color="secondary" size="lg">
            LYRA stakers will receive DRV rewards (separate to holder points)
          </BodyText>
          <YStack marginLeft="auto" alignItems="flex-end">
            <Tooltip
              trigger={
                <XStack cursor="help" alignItems="center">
                  <VideoText color="green" size="lg" textAlign="right">
                    {isLoading
                      ? '...'
                      : formatNumber(bigNumberToString(stakedLyraBalance, WEI_DECIMALS), {
                          maxDps: 2,
                        })}{' '}
                    stkLYRA
                  </VideoText>
                  <BodyText color="secondary" size="lg">
                    &nbsp;[?]
                  </BodyText>
                </XStack>
              }
              content={
                lyraBalances.lyra.total !== BigInt(0) ? (
                  <YStack gap="$2">
                    <YStack>
                      <BodyText>Ethereum</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(
                            lyraBalances.sunkLyra.ethereum + lyraBalances.stkLyra.ethereum,
                            WEI_DECIMALS
                          )
                        )}{' '}
                        Staked LYRA
                      </BodyText>
                    </YStack>
                    <YStack>
                      <BodyText>Arbitrum</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(
                            lyraBalances.sunkLyra.arbitrum + lyraBalances.stkLyra.arbitrum,
                            WEI_DECIMALS
                          )
                        )}{' '}
                        Staked LYRA
                      </BodyText>
                    </YStack>
                    <YStack>
                      <BodyText>Optimism</BodyText>
                      <BodyText>
                        {formatTruncatedNumber(
                          bigNumberToNumberUNSAFE(
                            lyraBalances.sunkLyra.optimism + lyraBalances.stkLyra.optimism,
                            WEI_DECIMALS
                          )
                        )}{' '}
                        Staked LYRA
                      </BodyText>
                    </YStack>
                  </YStack>
                ) : null
              }
            />
          </YStack>
        </Section.XStack>
        <Section.Separator />
        <Section.XStack alignItems="center">
          <SkewTextBox title="Holder" width={TEXT_BOX_WIDTH} />
          <BodyText color="secondary" size="lg">
            Holder points will convert pro-rata to DRV
          </BodyText>
          <YStack marginLeft="auto" alignItems="flex-end">
            <VideoText color="green" size="lg" textAlign="right">
              {isLoading ? '...' : formatNumber(lyraBalances.holderPoints, { maxDps: 2 })} HP
            </VideoText>
            <BodyText color="secondary" textAlign="right">
              {formatPercentage(lyraBalances.holderPointsShare)}
            </BodyText>
          </YStack>
        </Section.XStack>
      </Section>
    </>
  )
}
